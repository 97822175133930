import useScreenSize from './useScreenSize';

export default function Footer() {
  // footer component
  const screenSize = useScreenSize();

  return (
    <footer
      className="d-flex flex-column justify-content-center align-items-center bg-white shadow-lg pb-2 pt-4 fs-6"
      style={{ zIndex: 3 }}
    >
      <div
        className={`d-flex justify-content-center align-items-center ${screenSize.width > 680 ? '' : 'flex-column'}`}
        style={{ gap: `${screenSize.width > 680 ? '50px' : '15px'}` }}
      >
        <div>
          Nati Bekele &nbsp;{' '}
          <a href="https://www.linkedin.com/in/nati-bekele" target="_blank" rel="noreferrer">
            {' '}
            <i className="bi bi-linkedin"></i>
          </a>
          &nbsp;{' '}
          <a href="https://github.com/natibek" target="_blank" rel="noreferrer">
            {' '}
            <i className="bi bi-github"></i>
          </a>
        </div>

        <div>
          Sawiros Abebe &nbsp;{' '}
          <a
            href="https://www.linkedin.com/in/sawiros-abebe-648663276/"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-linkedin"></i>
          </a>
          &nbsp;{' '}
          <a href="https://github.com/Abebes01" target="_blank" rel="noreferrer">
            {' '}
            <i className="bi bi-github"></i>{' '}
          </a>
        </div>
      </div>

      <br />
      <div> &copy; November 2024</div>
    </footer>
  );
}
