import { Hints } from './Hints';
import { UserInput } from './UserInput';
import { Login } from './Login';
import { CustomWikiPopup } from './CustomWiki';
import useScreenSize from './useScreenSize';
import { Dropdown, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Instruction from './Instruction';
import { D3Background } from './D3background';
import Footer from './Footer';
import { socket } from './App';

export default function Infinite() {
  /*
        Infinite Game mode component. 
        Organizes the Infinite game page and passes the "page = infinite" prop into UserInput and Hints components.
    */
  const screenSize = useScreenSize();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showInstructionPopup, setShowInstructionPopup] = useState(false);
  // const location = useLocation();

  const handleCustomPopupToggle = () => {
    setShowCustomPopup(!showCustomPopup);
  };

  const handleInstructionPopupToggle = () => {
    setShowInstructionPopup(!showInstructionPopup);
  };
  const toInfinitePage = () => {
    setSearchParams({ a: '' });
  };

  const toMultiplayerPage = () => {
    navigate('/multiplayer');
    socket.connect();
  };

  const toDailyPage = () => {
    setSearchParams({ a: '' });
    navigate('/daily');
  };

  const toHome = () => {
    setSearchParams({ a: '' });
    navigate('/');
  };

  return (
    <div>
      <div className="d-flex py-3 justify-content-between align-items-center sticky-top bg-white border-bottom header_background">
        {screenSize.width > 680 ? (
          <>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: '300px' }}
            >
              <Dropdown>
                <Dropdown.Toggle className="dropdown-style btn bg-off-white">
                  &#9776;
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={toHome}>
                    {' '}
                    <i className="bi bi-house"></i> <> &nbsp; Home</>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={toDailyPage}>
                    {' '}
                    <i className="bi bi-calendar"></i> <> &nbsp; Daily</>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={toInfinitePage}>
                    {' '}
                    <i className="bi bi-infinity"></i>
                    <> &nbsp; Infinite</>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={toMultiplayerPage}>
                    {' '}
                    <i className="bi bi-people"></i>
                    <> &nbsp; Multiplayer</>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleCustomPopupToggle}>
                    {' '}
                    <i className="bi bi-plus-lg"></i> <> &nbsp; Make Your Own</>{' '}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleInstructionPopupToggle}>
                    {' '}
                    <i className="bi bi-question"> </i> <>&nbsp; Instructions</>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="d-flex justify-content-center align-items-start">
              <h1 className="text-center pt-2 fs-3">GuessTheWiki</h1>
              <h2 className="text-center fs-5">Infinite</h2>
            </div>

            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: '300px' }}
            >
              <Login page={'infinite'} />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center pl">
              <div className="d-flex justify-content-center align-items-center px-2">
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-style btn bg-off-white">
                    &#9776;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={toHome}>
                      {' '}
                      <i className="bi bi-house"></i> <> &nbsp; Home</>{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={toDailyPage}>
                      {' '}
                      <i className="bi bi-calendar"></i> <> &nbsp; Daily</>{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={toInfinitePage}>
                      {' '}
                      <i className="bi bi-infinity"></i>
                      <> &nbsp; Infinite</>{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={toMultiplayerPage}>
                      {' '}
                      <i className="bi bi-people"></i>
                      <> &nbsp; Multiplayer</>{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleCustomPopupToggle}>
                      {' '}
                      <i className="bi bi-plus-lg"></i> <> &nbsp; Make Your Own</>{' '}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleInstructionPopupToggle}>
                      {' '}
                      <i className="bi bi-question"> </i> <>&nbsp; Instructions</>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-start px-2">
                <h1 className="text-center pt-2 fs-4">GuessTheWiki</h1>
                <h2 className="text-center fs-5">Infinite</h2>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center pr">
              <Login page={'infinite'} />
            </div>
          </>
        )}
      </div>
      <div className="app-container">
        <div className="content">
          <br />
          <br />

          <Hints page={'infinite'} />
          <UserInput page={'infinite'} />

          <Modal
            show={showCustomPopup}
            onHide={handleCustomPopupToggle}
            centered
            className="position-absolute top-50 start-50  translate-middle w-75"
          >
            {/* Modal popup for the custom wiki */}
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body className="d-flex justify-content-center align-items-center">
              <CustomWikiPopup />
            </Modal.Body>
          </Modal>

          <Modal
            show={showInstructionPopup}
            onHide={handleInstructionPopupToggle}
            centered
            className="position-absolute start-50 top-50  translate-middle w-75"
          >
            {/* Modal popup for the instrutions */}
            <Modal.Header closeButton>
              <Modal.Title>How to Play</Modal.Title>
            </Modal.Header>

            <Modal.Body className="d-flex justify-content-center align-items-center">
              <Instruction />
            </Modal.Body>
          </Modal>
        </div>
        {screenSize.width < 680 ? <></> : <D3Background />}

        <Footer />
      </div>
    </div>
  );
}
