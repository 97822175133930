import { Modal } from 'react-bootstrap';
import { CustomWikiPopup } from './CustomWiki';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Instruction from './Instruction';
import './main.css';
import { D3HomeBackground } from './D3HomeBackground';
import { ReactComponent as Icon } from './assets/icon.svg';
import useScreenSize from './useScreenSize';

export default function HomePage() {
  /*
    HomePage component that has buttons that link to Daily, Infinite, Multiplayer modes.
    Info about the game and Custom Game mode are displayed in a Modal
    */
  const navigate = useNavigate();
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [showInstructionPopup, setShowInstructionPopup] = useState(false);

  const screenSize = useScreenSize();

  const handleCustomPopupToggle = () => {
    setShowCustomPopup(!showCustomPopup);
  };

  const handleInstructionPopupToggle = () => {
    setShowInstructionPopup(!showInstructionPopup);
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 4,
        color: 'black',
      }}
    >
      {screenSize.width < 680 ? <></> : <D3HomeBackground />}
      <div className="inner d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center p-5 border border-2 border-black rounded shadow-lg">
          <Icon width="50px" height="50px" />

          <div className="fs-2 pb-4 pt-2">GuessTheWiki</div>
          <button
            onClick={() => {
              navigate('/daily');
            }}
            className="button w-75 py-2 my-1"
            style={{ height: 'fit-content' }}
          >
            {' '}
            Daily{' '}
          </button>
          <button
            onClick={() => {
              navigate('infinite');
            }}
            className="button w-75 py-2 my-1"
            style={{ height: 'fit-content' }}
          >
            {' '}
            Infinite{' '}
          </button>
          <button
            onClick={() => {
              navigate('/multiplayer');
            }}
            className="button w-75 py-2 my-1"
            style={{ height: 'fit-content' }}
          >
            {' '}
            Multiplayer{' '}
          </button>
          <button
            onClick={handleCustomPopupToggle}
            className="button w-75 py-2 my-1"
            style={{ height: 'fit-content' }}
          >
            {' '}
            Custom Game{' '}
          </button>
          <button
            onClick={handleInstructionPopupToggle}
            className="button w-75 py-2 my-1"
            style={{ height: 'fit-content' }}
          >
            {' '}
            How To Play{' '}
          </button>
        </div>

        <Modal
          show={showCustomPopup}
          onHide={handleCustomPopupToggle}
          centered
          className="position-absolute top-50 start-50  translate-middle w-75"
        >
          {/* Custom game modal popup */}
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body className="d-flex justify-content-center align-items-center">
            <CustomWikiPopup />
          </Modal.Body>
        </Modal>

        <Modal
          show={showInstructionPopup}
          onHide={handleInstructionPopupToggle}
          centered
          className="position-absolute start-50 top-50 translate-middle w-75"
        >
          {/* Info modal popup */}
          <Modal.Header closeButton>
            <Modal.Title>How to Play</Modal.Title>
          </Modal.Header>

          <Modal.Body className="d-flex justify-content-center align-items-center">
            <Instruction />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

